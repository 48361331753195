@import "variables";

// D. Include any default variable overrides here (though functions won't be available)
html,
body {
  height: 100%;
}

a {
  text-decoration: none;
  color: $black;
}

#root {
  min-height: 100%;
  display: contents;
}

/* Adjustments for the sidebar */
.sidebar-paper {
  width: 250px; /* Adjust width as needed */
  flex-shrink: 0;
}

/* Content shift when sidebar is active */
.shifted {
  margin-left: 250px; /* Same as sidebar width */
  transition: margin-left 0.2s ease-out; /* Adjust transition as desired */
}

.filenotfound {
  align-self: center;
}

button {
  svg {
    margin-right: 5px;
  }
}

.MuiInputLabel-root {
  font-size: 0.9rem !important;
  line-height: 0.8em !important;
  top: -3px;
}

.MuiInputBase-input.MuiInput-input {
  font-size: 0.9rem;
  margin: 5px;
}

.items-filter-options-container {
  .MuiInputBase-root {
    padding: 0;
  }
}

.MuiDataGrid-columnHeaders {
  background-color: $light;
}

.MuiDataGrid-overlayWrapper {
  min-height: 100px;
}

.MuiDataGrid-footerContainer,
.MuiDataGrid-virtualScrollerRenderZone {
  background-color: $white;
}

.location-icon {
  display: none;
  padding: 4px;
  margin: 0;
  min-width: auto;
  border: 1px solid #dedede;
  border-radius: 3px;
}
.location-select {
  margin-left: auto;
}
.user-details {
  text-align: right;
}
.user-details-mobile {
  display: none;
}
.sub-menu-list {
  a {
    display: inline-block;
    width: 100%;
  }
  .active {
    font-weight: bold;
  }
}

@media screen and (max-width: 676px) {
  .user-details {
    display: none;
  }
  .user-details-mobile {
    display: block;
  }
  .location-icon {
    display: block;
  }
  .location-select {
    visibility: hidden;
    width: 0px;
    margin-left: unset;
  }
  .location-select .MuiInputBase-root.MuiOutlinedInput-root {
    width: 1px;
  }
  .main-container > .MuiBox-root {
    padding: 5px;
  }
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined {
    margin-left: 0;
  }
  .mobile-header h6 {
    font-size: 0.9rem;
  }
}
a:hover {
  font-weight: 700;
}

.export-csv {
  min-width: 116px;
}

@media (max-width: 991px) {
  .hide-crumb {
    display: none !important;
  }
  .filter-bar .date-filter {
    width: 50%;
    display: table-cell;
  }
  .filter-bar-date {
    display: inline-flex;
  }
}

@media (min-width: 992px) {
  .show-crumb {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .export-csv {
    min-width: 116px;
  }
  .filter-bar-date {
    display: block;
    margin-top: 1rem;
  }
}

.custom-loader {
  position: absolute;
  top: 0;
}

hr.MuiDivider-fullWidth {
  opacity: 1;
}

span.MuiStepLabel-alternativeLabel {
  text-align: left;
  .Mui-active {
    color: #2a9d8f;
  }
}
.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  width: 100%;
}

div.step-description {
  margin-left: 0;
  padding-left: 0;
  border-left: unset;
}

.MuiCardHeader-content {
  .MuiCardHeader-title {
    font-size: 1.1rem;
    font-weight: 700;
  }
}
div.MuiPaper-elevation1 {
  padding: 8px 0 8px 0;
}
.MuiPaper-elevation1 div.MuiTabPanel-root {
  padding: 0;
  margin-top: -30px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation1.charts-header {
  text-align: right;
  h6 {
    text-align: center;
  }
}

.charts-header .MuiTabs-root {
  min-height: 36px;
}

.MuiTabs-flexContainer .MuiTab-root.MuiTab-textColorPrimary {
  text-transform: capitalize;
  min-height: 36px;
  padding: 5px;
}
.donut-chart
  div.apexcharts-legend.apx-legend-position-bottom
  .apexcharts-legend-series {
  align-items: baseline;
  margin: 5px 10px !important;
}

p#demo-simple-select-helper-text {
  margin-left: 0;
}
label#demo-simple-select-label {
  line-height: initial !important;
}
label#secret-key-input-label {
  top: 3px !important;
}
input#secret-key-input {
  height: 25px;
}

.accordian-input-fields {
  padding-left: 50px;
  padding-right: 50px;
  > div {
    display: inline-flex;
    align-items: center;
    column-gap: 20px;
    width: 75%;
    > label {
      width: 34%;
      + {
        div {
          width: 60%;
          > div {
            width: 100%;
            > div {
              height: 45px;
              > input {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
              }
            }
          }
        }
      }
    }
    input {
      height: auto;
      padding: 10px;
    }
  }
}

.productView-accordian-rightList {
  > div {
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 4px 15px;
    > img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center;
    }
    > div {
      padding: 0px 15px !important;
      width: 100%;
      > div {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.hide-header {
  .MuiDataGrid-columnHeaders {
    display: none;
  }
}

.sidebarImageListing {
  padding: 0 !important;
}

.productView-accordian-rightList {
  .currentItemName {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 0px 20px;
    background: #fff;
    margin-bottom: 10px;
    .currentItemTop {
      display: inline-flex;
      align-items: center;
      column-gap: 5px;
      padding: 0 !important;
      p {
        font-size: 18px;
        font-weight: 800;
        margin: 0;
        span {
          font-weight: 400;
        }
      }
    }
    .currentItemBottom {
      display: inline-flex;
      align-items: center;
      column-gap: 5px;
      padding-left: 30px !important;
      p {
        margin: 10px 0;
      }
    }
  }
}

.productView-accordian-rightList {
  > div.hide-header {
    padding: 0;
    border: 0;
    height: 100%;
    > div {
      padding: 0 !important;
      > div {
        &:last-child {
          > div {
            > div {
              width: 100%;
              row-gap: 5px;
              background: transparent;
              > div {
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}
.Toastify__toast-container {
  width: auto !important;
  max-width: "50%";
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.productView-accordian-main {
  background: transparent;
  padding-top: 0px !important;
  position: fixed;
  width: 250px;
  height: calc(100vh - 95px);
}

div.accordian-container.MuiPaper-elevation1 {
  padding: 0px !important;
}
.productView-accordian-rightList {
  padding-top: 0px !important;
  height: calc(100% - 35px);
}

.hide-input-arrow {
  input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
}

.MuiDataGrid-row.Mui-active {
  background-color: #CCE7E9;
}
.MuiDataGrid-row.Mui-inactive {
  background-color: #FFF;
}

.MuiTablePagination-root {
  overflow: unset !important;
}

.edit-grid-image-cell {
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 45px;
    max-height: 45px;
  }
}

.edit-grid-product-detail-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  .product-short-description {
    font-size: 13px;
    font-weight: 500;
    color: $gray-700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 152px;
  }
}

.attachment-grid-image-cell {
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 80px;
    max-height: 80px;
  }
}

div.css-1nmdiq5-menu div.css-1n6sfyn-MenuList {
  height: unset;
}

.required-field-asterisk-icon {
  color: #d32f2f;
}

.image-slider-overlay {
  position: fixed;
  top: 30px;
  left: 140px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider-container {
  position: relative;
}

.pdf-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-container {
  position: relative;
  top: 3%;
  left: 10%;
  width: 65%;
  min-width:700px;
  max-width: 1300px;
  height: 85%;
  min-height: 500px;
  max-height: 900px;
}

.slider .slide {
  display: flex;
    align-items: center;
    justify-content: center;
}

.close-btn {
  position: absolute;
  top: -5px;
  right: -34px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.image-slider {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider img {
  max-width: 600px;
  max-height: 600px;
}

.carousel .slide .legend {
  opacity: 0 !important;
}

.carousel .slide:hover {
  .legend {
    opacity: 1 !important;
  }
}

.MuiButtonBase-root.MuiButton-root.tag-selected-button {
  margin: 8px 5px;
  cursor: default;
}

.title-text {
  margin-left: 10px;
  font-size: xx-large;
  font-weight: 800;
}

.edit-item-main-container {
  height: 70%;
  overflow: hidden;
  transition: width 0.5s;
}

.notification {
  &_popup {
    .MuiPaper-root {
      overflow: visible !important;
    }
  }

  &_popup-arrow {
    width: 40px;
    height: 30px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: -30px;
    left: 50%;

    &::before {
      content: ' ';
      position: absolute;
      width: 25px;
      height: 25px;
      background: #FFF;
      transform: rotate(45deg);
      top: 22px;
      left: 7px;
      box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    }
  }
}

.Toastify__toast-theme--dark {
  background: $primary !important;
}

/* START: dashboard css */

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 16px;
  margin-top: 16px;
}

.product-card {
  width: 100%;
  min-width: 265px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.product-image-container {
  width: 205px;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  .product-thumbnail {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    cursor: pointer;
  }
}

.product-details {
  width: 100%;
  text-align: center;
  .product-name {
    cursor: pointer;
    display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
  }
  .product-detail-content {
    cursor: pointer;
  }
}

.product-detail-row {
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-wrap: wrap;
}

.pipe {
  margin-left: "2px";
  margin-right: "2px";
}

.bold-text {
  font-weight: bold;
}

.note-input {
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 576px) {
  .product-container {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .product-container {
    justify-content: start;
  }
}

@media (min-width: 992px) {
  .product-card {
    width: calc(25% - 32px);
  }
}

@media (min-width: 1200px) {
  .product-card {
    width: calc(25% - 12px);
  }
}

/* END: dashboard css */


/* START: Pagination css */

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: 16px;
}

.rows-per-page {
  min-width: 120px;
}

.pagination-info {
  flex: 1;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 4px;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.pagination li a:hover {
  background-color: #f0f0f0;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* END: Pagination css */

.image-thumbnail-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
}

.image-thumbnail-container:hover .attachment-hover-icon-button {
  opacity: 0.8;
  background: $dark;
  color: #fff;
}

.attachment-hover-icon-button {
  opacity: 0;
  transition: opacity 0.3s;
  svg {
    margin-right: 0;
  }
}

.active-accordian-expanded {
  background-color: $light !important;
}

.product-comment-list-content {
  width: 100%;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 15px;
  margin-left: 10px;
}

.product-comment-list-content:hover .comment-content-hover-icon {
  opacity: 0.8;
}

.comment-content-hover-icon {
  opacity: 0;
  transition: opacity 0.3s;
  float: right;
  margin-top: -25px;
}

.bulk-create-grid-cell .MuiDataGrid-row.MuiDataGrid-row--dynamicHeight,
.bulk-update-grid-cell .MuiDataGrid-row.MuiDataGrid-row--dynamicHeight {
    min-height: 40px !important;
  
}

/* Filters css for tablet view (600px to 1024px) */
@media (min-width: 600px) and (max-width: 1024px) {
  .filter-autocomplete-dropdown {
    .MuiFormControl-root.MuiFormControl-fullWidth {
      width: 190px;
    }
  }
}

/* START: Main Container responsive css */
@media (min-width: 768px) and (max-width: 990px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 900px;
    }
  }
}

@media (min-width: 991px) and (max-width: 1023px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 1000px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1219px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 1100px;
    }
  }
}

@media (min-width: 1220px) and (max-width: 1389px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 1250px;
    }
  }
}

@media (min-width: 1390px) and (max-width: 1500px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 1250px;
    }
  }
}

@media (min-width: 1940px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 1660px;
    }
  }
}

@media (min-width: 2250px) {
  .MuiBox-root {
    .MuiContainer-root.MuiContainer-maxWidthXl {
        max-width: 1900px;
    }
  }
}
/* END: Main Container responsive css */

.custom-quill {
  width: 100%;
  margin: 0 auto;
}

.custom-quill .ql-editor {
  min-height: 72px;
  overflow-y: hidden;
  font-size: $font-size-base;
  font-family: $font-family-sans-serif;
}

/* Quote Screen CSS */
.quote-accordian-fields {
  .form-section {
    display: flex;
    flex-direction: column;
  }
  
  .quote-form-control-row {
    margin: 5px;
    width: 32%;

    > label {
        width: 100%;
        margin-bottom: 8px;
        + {
          div {
            width: 95%;
            > div {
              width: 100%;
              > div {
                height: 56px;
                > input {
                  padding-top: 0 !important;
                  padding-bottom: 0 !important;
                }
              }
            }
          }
        }
    }
  }
}

/*START: Notification css */
.notification-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

/* Customize scrollbar width for this specific container */
.notification-container::-webkit-scrollbar {
  width: 8px; /* Smaller width */
}

/* Customize scrollbar track */
.notification-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Customize scrollbar thumb */
.notification-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Hover effect for scrollbar thumb */
.notification-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
/* END: Notification css */

/* START: Attachment Section css */
.attachment-card-content {
  padding: 8px 8px 0 !important;
  position: relative;

  .attachment-file-name {
    margin: 10px 0;
    text-align: center;
    white-space: normal;
    word-break: break-word;
    min-height: 35px;
  }

  .attachment-date {
    text-align: center;
    display: block;
    min-height: 20px;
  }
}

.attachment-card-content .hover-checkbox {
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Disable interaction when hidden */
}

.attachment-card-content:hover .hover-checkbox {
  opacity: 1; /* Show on hover */
  pointer-events: auto; /* Enable interaction on hover */
}

.attachment-card-content.checked .hover-checkbox {
  opacity: 1; /* Always show if checked */
  pointer-events: auto; /* Enable interaction when checked */
}
/* END: Attachment Section css */

/* START: Project Overview Dashboard css */
.project-overview-dashboard-container {
  padding: 16px;
  height: 100%;
  
  .header-title-container {
    margin-bottom: 20px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .total-active-projects-title {
      font-weight: bold;
    }  
  }
  
  .project-overview-grid-container {
    height: 100%;
  }
  
  .project-overview-grid-item {
    padding: 10px;
  }
  
  .project-overview-divider {
    margin: 10px 0;
  }
  
  .no-data-found-message {
    text-align: center;
    margin: 20px 0;
  }
  
}

.project-overview-dashboard-container,
.user-dashboard-main-container {

  .graph-label-container {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 35px);
    margin-left: 35px;

    >div {
      flex: 1;
      text-align: center;
      font-size: 13px;

      .label-box {
        border: 1px solid #000;
        padding: 5px;
        margin: 0 7px;
        display: inline-block;
        max-width: calc(100% - 22px);
      }
    }

    .content {
      max-height: 140px;
      overflow: hidden;
      transition: max-height 0.3s ease; /* Smooth transition */
      position: relative;

      ul {
        padding: 0;
        margin: 0;
        text-align: left;
        list-style-position: inside;
      }
    }

    .content.expanded {
      max-height: none;
      /* Remove max-height limit when expanded */
    }

    .toggle-label {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
      text-align: center;
      display: block;
    }
  }
}

.user-dashboard-main-container {
  padding: 16px;
  height: 100%;

  .user-dashboard-grid-container {
    display: flex;
    height: auto;
  
    .no-scroll .MuiDataGrid-virtualScroller {
      overflow-y: hidden !important;
    }
  }
}

/* END: Project Overview Dashboard css */

.manage-notification-subscription-dialog-container {

  .close-icon-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #9e9e9e;
  }

  .dialog-content {
    padding: 0 24px;
  }

  .card {
    margin-bottom: 24px;
  }

  .card-actions {
    justify-content: space-between;
    padding: 0 20px;
  }

  .filters-title {
    font-size: 16px;
    font-weight: 700;
  }

  .flex-wrap-box {
    display: flex;
    flex-wrap: wrap;
  }

}

/* QuoteDetailsComparisonModal.css */
.quote-details-comparison-modal {

  .dialog-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .dialog-title {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    position: relative;
  }

  .close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: grey;
  }

  .grid-header {
    font-weight: bold;
    text-align: center;
  }

  .grid-content {
    text-align: center;
  }

  .grid-item {
    border: 1px solid #e0e0e0;
    padding: 8px;
    text-align: center;
  }

}






